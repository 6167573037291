/*:root {
    --main-font: 'Roboto Condensed', sans-serif;
    --secondary-font: 'Orbitron', sans-serif;
    --main-color: #0086FF;
    --secondary-color: #FFA03C;
    --text-color-white: #FFFFFF;
    --accent-color-light: #FFA03C;
    --accent-color-dark: #FFA03C;
    --accent-color-darker: #FFA03C;
    --accent-color-darkest: #FFA03C;
  }*/



.socialCard {
    position: relative;
    aspect-ratio: 3.59/1;
    display: flex;
    align-items: baseline;
    flex-direction: column;
    justify-content: space-around;
    padding: 60px 48px;
    /*background-image: url('../assets/img/SocialCard.webp');*/
    background-size: 100% 100%;
    /* Use the aspect ratio of the background */
    background-repeat: no-repeat;
    background-position: center;
    /* Add rounded corners like the screenshot */
    margin: 0 1vw;
    margin-top: 110px;
    align-self: center;
    width: 92%;
    /*width: 906px;*/

    border-radius: 36px;
    border: 2px solid rgba(255, 255, 255, 0.25);
    background: var(--Custom-bg0, linear-gradient(135deg, rgba(10, 15, 41, 0.25) 0%, rgba(10, 15, 41, 0.13) 40%, rgba(159, 1, 173, 0.13) 72%, rgba(159, 1, 173, 0.25) 100%), #030511);
    box-shadow: 0px -4px 24px 0px rgba(10, 15, 41, 0.50) inset, -5px 5px 24px 0px rgba(255, 255, 255, 0.05);

    overflow: hidden;
}

.socialCard img {
    content: url('../assets/img/RocketFooter.webp');
    position: absolute;
    right: -8%;
    bottom: -28%;
    width: 800px;
    height: 800px;
}

.socialCard .social-button {
    aspect-ratio: 1;
    pointer-events: auto;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    width: 80px;
    display: flex;
    overflow: hidden;
}

.socialCard .social-button svg {
    width: 32px !important;
    height: 32px !important;
}

.text-container {
    max-width: 60%;
    padding-left: 4vw;
    margin-top: -52px;
}

.socialCard h1 {
    color: #0086FF;
    font-family: var(--secondary-font);
    font-size: 48px;
    font-weight: 400;
    line-height: 1.2;
}

.socialCard p {
    color: #FFFFFF;
    font-family: var(--main-font);
    font-size: 32px;
    line-height: 1.6;
    margin-top: 10px;
}

.icons-container {
    display: flex;
    align-items: center;
    gap: 20px;
    padding-left: 4vw;
    pointer-events: auto;
}



.footer-base {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-items: flex-start;
}

.FooterContainer {
    /* 50% of 1080px */
    position: relative;
    align-content: center;
    width: 100vw;
    height: 208px;
    /* 60% of 1080px */

}

/* Footer */
footer {
    color: #fff;
    font-family: 'Orbitron', sans-serif;
    position: absolute;
    bottom: 0;
    width: 80%;
    text-align: center;
    padding: 10px 0;
    background-color: var(--footer-bg-color);
    color: var(--text-color);
    font-size: 0.8em;
    transform-origin: center;
    transform: translateX(50%);
    right: 50%;
}

.left-section {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    text-align: left;
    max-width: 20%;
    padding-right: 33%;
}

.footer-logo {
    width: 236px;
}

.footer-description {
    color: var(--secondary-color);
    padding-top: 10px;
    font-size: 18px;
}

.menu-columns {
    display: flex;
    gap: 2rem;
    width: 75%;
    justify-content: space-between;
}

.menu-columns h4 {
    margin: 0;
    color: white;
    font-size: 18px;
}

.menu-column {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.menu-column a {
    margin-bottom: 10px;
    height: 40px;
    width: 40px;
    pointer-events: all;
    cursor: pointer;
}

.menu-list {
    list-style-type: none;
    padding: 0;
}

.menu-list li {
    margin-bottom: 25px;
}

.footer-link {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;
    pointer-events: auto;
}

.footer-link:hover {
    text-decoration: underline;
}

.bottom-part {
    margin: 0;
    padding: 0;
    text-align: center;

}

.bottom-part span {
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    padding-bottom: 20px;
}

.bottom-part img {
    width: 60px;
    margin-top: 10px;
    margin-bottom: 10px;
    position: absolute;
    right: 50%;
    transform: translateX(50%);
}

.footer-divider {
    border: 1px solid #fff;
    margin-bottom: 1rem;

}

.bottom-part p {
    margin: 0 0;
    color: white;
}

@media (max-width: 1750px) {
    .socialCard {
        height: 425px;
    }
}

@media (max-width: 1350px) {

    .socialCard h1 {
        font-size: 32px;
    }

    .socialCard p {
        font-size: 24px;
    }

}

@media (max-width: 800px) {
    .socialCard {
        height: 600px;
        margin-bottom: 150px;
        /*background-image: url(../assets/img/SocialCard_mobile.webp);*/
        justify-content: flex-start;
        padding: 0 1vw;
        width: 90%;
    }

    .text-container {
        max-width: 80%;
        padding-left: 10vw;
        margin-top: 0;
    }

    .socialCard h1 {
        font-size: 36px;
    }

    .socialCard p {
        font-size: 24px;
    }

    .socialCard img{
        height: 300px;
        width: 300px;
        bottom: -17%;
    }

    .icons-container {
        padding-left: 10vw;
        align-items: center;
        width: 54vw;
    }

    .footer-base {
        flex-direction: column;
        align-items: center;
    }

    .left-section {
        max-width: 100%;
        text-align: center;
        padding: 0;
        text-align: left;
    }

    .footer-logo {
        width: 250px;
        padding-bottom: 20px;
    }

    .menu-columns {
        width: 100%;
    }

    .footer-description {
        padding-bottom: 20px;
    }



    .bottom-part {
        margin-top: 1rem;
        justify-self: center;
    }

    .bottom-part p {
        padding-top: 80px;
    }
}